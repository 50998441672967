import ServiceApi from "../ServiceApi";
import ServiceReconciliationFilter from "./ServiceReconciliationFilter";
export default class ServiceReconciliationTransaction {
    static _microserviceName = "free";

    static _requestRouts = {
        toggleRevise: '/transactions/toggle-revise',
        transactionsRemove: '/transactions/remove',
        sendBudget: '/transactions/send-budget',
        postDoneIds: '/transactions/done-ids',
        postApproveIds: '/transactions/approve-ids'
    };

    static async postIds(filterData = [], type = '', actionAfter = () => {}, actionError = () => {}) {
        return await this._postIds(filterData, type, actionAfter, actionError)
    }

    static async _postIds(filterData, type, actionAfter, actionError) {
        const route = type === 'approved' ? this._requestRouts.postApproveIds : this._requestRouts.postDoneIds;
        filterData = ServiceReconciliationFilter._getFilterBefore(filterData)

        try {
            const {data: res} = await ServiceApi.post(this._microserviceName, route, filterData)
            const transactions = res?.data || []
            actionAfter(transactions)
        } catch (error) {
            console.log(error);
            actionError(error)
        }
    }

    static async toggleRevise(priceId = '') {
        return this._toggleRevise(priceId);
    }

    static async _toggleRevise(priceId = '') {
        const reqBody = this._toggleReviseBefore(priceId);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.toggleRevise, reqBody);
            const {data = {}} = res;
            const {data: dataRevised = {}} = data;
            return Boolean(dataRevised?.is_revised ?? false);
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _toggleReviseBefore(priceId = '') {
        return {transaction: String(priceId)};
    }

    static async toggleRevises(priceIds = [], isRevised = false) {
        return this._toggleRevises(priceIds, isRevised);
    }

    static async _toggleRevises(priceIds = [], isRevised = false) {
        const requestBody = this._toggleRevisesBefore(priceIds, isRevised);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.toggleRevise, requestBody);

            const {data = {}} = res;
            const {data: dataRevised = {}} = data;
            return Boolean(dataRevised?.is_revised ?? false);
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _toggleRevisesBefore(priceIds = [], isRevised = false) {
        const requestBody = {
            transactions: priceIds.map(priceId => String(priceId)),
            is_revised: Boolean(isRevised)
        };

        return requestBody;
    }

    static async transactionsRemove(priceIds = []) {
        return this._transactionsRemove(priceIds);
    }

    static async _transactionsRemove(priceIds = []) {
        const reqBody = this._transactionsRemoveBefore(priceIds);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.transactionsRemove, reqBody);
            return true;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _transactionsRemoveBefore(priceIds = []) {
        return {transactions: priceIds.map(priceId => String(priceId))};
    }

    static async sendBudget(filterData = []) {
        return this._sendBudget(filterData);
    }

    static async _sendBudget(filterData = []) {
        const reqBody = this._sendBudgetBefore(filterData);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.sendBudget, reqBody);

            return true;
        } catch (error) {
            console.log(error);
            const errorMsg = this._sendBudgetError(error);
            throw new Error(errorMsg);
        }
    }

    static _sendBudgetBefore(filterData = []) {
        const {filter = {}} = ServiceReconciliationFilter.getFilterBefore(filterData);

        return {...filter};
    }

    static _sendBudgetError(error = {}) {
        const { response = {} } = error;
        const { data: responseData = {} } = response;
        const { data: responseErrors = {} } = responseData;
        const {count_map_not_matched: countMapNotMathed = '', count_not_valid: countNotValid = ''} = responseErrors;
        const errorMsg = `В выборке: ${countMapNotMathed} размещений не сверенных с мапом; ${countNotValid} размещений без назначенных юр. лиц. Исправьте и попробуйте снова`;

        return errorMsg
    }
}
